.rin-input-container {
    display: flex;
}

.rin-input-element {
    font: inherit;
    background: 0 0;
    color: currentColor;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    vertical-align: bottom;
    text-align: inherit;
    -webkit-appearance: none;
    caret-color: #3f51b5;
    margin-top: -0.0625em;
}

.unselected-option {
    .mat-option-text {
        font-weight: bold;
    }
}

input {
    &.input-hide-spinners {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        &[type=number] {
            -moz-appearance: textfield;
        }
    }
}
