@import 'assets/scss/theme-variables';

.auth-layout {
    .copyright {
        font-size: 14px;

        a {
            color: $color-white;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.alert {
    font-size: 14px;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 0;
}

.alert-danger {
    color: $color-input-error;
    background-color: $color-error-background;
    border: 1px solid $color-error-border;
}

.view-content {
    .panel {
        &.panel-default {
            border: none;
            box-shadow: none;
            margin-bottom: 10px;
        }

        .panel-collapse {
            overflow: hidden;

            .panel-body {
                border: none;
            }
        }
    }
}

.table {
    &> {
        thead {
            &.table-head {
                background-color: $color-table-header;
            }

            &:first-child {
                &> {
                    tr {
                        &:first-child {
                            &> {
                                th {
                                    border-top: 1px solid $color-table-border;
                                }
                            }
                        }
                    }
                }
            }

            &> {
                tr {
                    &> {
                        th {
                            border-color: $color-table-border;
                            border-bottom: 1px;
                        }
                    }
                }
            }
        }
    }

    &.table-bordered {
        &> {
            tbody {
                &> {
                    tr {
                        &> {
                            td {
                                border-color: $color-table-border;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dataTables_wrapper {
    padding: 0;

    thead tr {
        background-color: $color-table-header;
    }

    .dt-toolbar-footer {
        overflow: hidden;
    }

    .dataTable {
        margin: 0 !important;

        thead {
            tr {
                th {
                    vertical-align: middle;
                    font-size: 14px;
                    border-bottom: 0;
                    color: $color-black;
                    white-space: nowrap;
                    word-break: break-all;
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 14px;
                    word-break: break-all;
                }
            }
        }

        &.table-striped {
            &>tbody {
                &>tr {
                    background-color: $color-table-row-alt;

                    &:nth-of-type(odd) {
                        background-color: $color-white;
                    }
                }
            }
        }
    }

    .link-button {
        cursor: pointer;
    }

    .dt-toolbar-footer {
        border: 0;
        background-color: $color-grey;

        .pagination {
            .paginate_button i {
                margin: 0 6px;
            }

            font-size: $font-base-size;

            a {
                box-shadow: none;
                color: $color-black;
                -webkit-box-shadow: none;
                border: 0;
                background-color: $color-grey;

                &:hover {
                    text-decoration: underline;
                    background-color: $color-grey;
                    color: $color-light-blue-100;
                }

                &:focus {
                    background-color: $color-grey;
                }
            }

            .active a {
                font-weight: 700;
                color: $color-navy;

                &:hover {
                    text-decoration: none;
                    background-color: $color-grey;
                }

                &:focus {
                    background-color: $color-grey;
                }
            }
        }
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }

    100% {
        -webkit-transform: rotate(360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);
        /* IE 9 */
        transform: rotate(0deg);
        /* Firefox 16+, IE 10+, Opera */
    }

    100% {
        -webkit-transform: rotate(360deg);
        /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);
        /* IE 9 */
        transform: rotate(360deg);
        /* Firefox 16+, IE 10+, Opera */
    }
}

.container {
    .login-box {
        .input-builder {
            padding-right: 0;
            margin-bottom: 0;
        }
    }
}

.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background: transparent;
}

/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}
