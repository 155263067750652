.tabs-container {
    .mat-mdc-tab-group {
        margin: 0 10px;
        background-color: $color-grey-ghost-pastel;
        border-top: 1px solid $color-grey-pastel;

        mat-tab-header {
            border-left: 1px solid $color-grey-pastel;
            border-right: 1px solid $color-grey-pastel;
            border-bottom: 1px solid $color-grey-pastel;

            .mat-mdc-tab-labels {
                .mdc-tab {
                    font-family: $font-family;
                    font-size: 16px;
                    font-weight: 400;
                    letter-spacing: normal;
                    border-right: 1px solid $color-grey-pastel;
                    flex-grow: 0;
                }

                .mdc-tab.mdc-tab--active {
                    background: $color-white;

                    .mdc-tab__content {
                        .mdc-tab__text-label {
                            color: $color-black !important;
                        }
                    }
                }

                .mdc-tab:not(.mdc-tab--active):not(.mat-mdc-tab-disabled) {
                    .mdc-tab__content {
                        .mdc-tab__text-label {
                            color: $color-light-blue-500 !important;
                        }
                    }
                }

                .mdc-tab__content {
                    i {
                        margin-right: 8px;
                    }
                }

                .mdc-tab-indicator {
                    .mdc-tab-indicator__content {
                        align-self: flex-start;
                        border-color: $color-tab-ink-bar !important;
                    }
                }

                &:hover {

                    .mdc-tab__content:hover {
                        color: $color-light-blue-500 !important;
                        text-decoration: underline;
                    }
                }


                &.mdc-tab--active {
                    border-left: 1px solid $color-boder-gray;
                    border-right: 1px solid $color-boder-gray;
                    background-color: $color-white;
                    cursor: default;

                    .mdc-tab__content {
                        color: $color-black-raising;
                    }

                    &:hover {

                        .mdc-tab__content {
                            text-decoration: none;
                        }
                    }
                }

                .mat-mdc-tab-disabled {
                    opacity: 1;
                }
            }
        }

        .mat-mdc-tab-body-wrapper {
            background-color: $color-white;
        }
    }

    mat-tab-group.info {
        .mdc-tab:last-child {
            margin-left: auto;
            justify-content: center;
            min-width: auto !important;

            .mdc-tab__content {
                color: $color-black-raising !important;
            }

            &:hover {

                .mdc-tab__content {
                    text-decoration: none !important;
                }
            }
        }
    }

    &.details-tabs-container {
        width: 100%;

        .mat-mdc-tab-group {
            border-top: none;
            margin: 0;

            .mat-mdc-tab-header {
                border: none;
                background-color: $bg-primary;
                text-transform: uppercase;

                .mat-mdc-tab-labels {
                    border: none;

                    .mdc-tab:nth-child(2) {
                        border-left: 4px solid $color-white;
                        border-right: 4px solid $color-white;
                    }

                    .mdc-tab:last-child {
                        padding: 0;
                    }

                    .mdc-tab {
                        .mdc-tab__content {
                            .mdc-tab__text-label {
                                color: $color-white !important;
                                text-decoration: none !important;
                            }

                            color: $color-white !important;

                            .mat-icon {
                                color: $color-white !important;
                                width: 18px !important;
                                font-size: 17px;
                                margin-top: -9px;
                                margin-right: 10px;
                                cursor: pointer;
                            }
                        }

                        &.mdc-tab--active {
                            background-color: $color-navy-60 !important;
                            cursor: default;

                            &:hover {

                                .mdc-tab__content {
                                    text-decoration: none;
                                }
                            }
                        }
                    }

                    &:hover {
                        .mdc-tab__content {
                            text-decoration: none;
                        }
                    }

                    .mdc-tab:not(.mdc-tab--active):not(.mat-mdc-tab-disabled) {
                        .mdc-tab__content {
                            .mdc-tab__text-label {
                                color: $color-white !important;
                            }
                        }
                    }
                }

                .mat-ink-bar {
                    display: none;
                }
            }
        }
    }

}

.mat-mdc-tooltip {
    font-size: 14px;
    color: $color-white;
}

.mat-mdc-tooltip.tooltip-break {
    max-width: inherit;
    white-space: pre-line;
    left: 0;
}

.cdk-overlay-container {
    .mat-mdc-datepicker-content .mat-calendar {
        height: auto;
    }

    .mat-mdc-calendar-table-header {
        tr:not(:first-child) {
            th {
                display: none;
            }
        }
    }
}

.mat-mdc-unelevated-button {
    letter-spacing: normal !important;
}

.mat-mdc-nav-list .mat-mdc-list-item {
    border-radius: 0 !important;
}

.mat-mdc-list-item,
.mat-mdc-list-item .mdc-list-item__primary-text,
.mdc-list-item__primary-text {
    all: unset;
}

.mdc-list-item__primary-text {
    font-size: .9rem !important;
}

.mdc-floating-label {
    font-size: 14px !important;
}

input.mat-mdc-input-element {
    font-size: 14px !important;
}

.mat-mdc-form-field-focus-overlay {
    all: unset;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.mat-mdc-form-field-focus-overlay {
    background: none !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
    padding: 0 !important;
}

.mat-mdc-radio-button.mat-accent {
    color: $color-green-wcag;
}

.mat-mdc-radio-button.mat-mdc-radio-checked {
    .mdc-form-field {
        .mdc-radio {
            .mdc-radio__background {
                .mdc-radio__outer-circle {
                    border-color: $color-green-wcag !important;
                }

                .mdc-radio__inner-circle {
                    border-color: $color-green-wcag !important;
                }
            }
        }
    }
}

.mat-mdc-checkbox.mat-mdc-checkbox-checked {
    .mdc-form-field {
        .mdc-checkbox {
            .mdc-checkbox__ripple {
                background: $color-green-wcag !important;
            }

            .mdc-checkbox__background {
                background: $color-green-wcag !important;
                border-color: $color-green-wcag !important;
            }
        }
    }

    .mdc-checkbox {
        .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
            background: $color-grey-taupe !important;
            border-color: $color-grey-taupe !important;
        }
    }
}

.mdc-checkbox__background {
    border-radius: 4px !important;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked .mdc-switch__track::after,
.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked .mdc-switch__handle::after {
    background: $color-green-identity-risk !important;
}

label {
    margin-bottom: 0;
}

.mat-mdc-icon-button {
    svg {
        margin-right: 22px;
    }
}

.mat-mdc-dialog-container .mdc-dialog__content {
    color: $color-black-raising !important;
    font-family: 'Source Sans Pro', 'Open Sans', Arial, Helvetica, 'Sans-Serif' !important;
}

.mdc-dialog__container {
    .mat-mdc-dialog-container .mdc-dialog__surface {
        border-radius: 5px !important;
        color: $color-black-raising;
    }

    .mat-mdc-dialog-actions {
        display: block;
        margin: 0;
        padding: 0;
        border-radius: 0 0 5px 5px;
        min-height: auto !important;

        &:last-child {
            margin: 0;
        }
    }
}

.mat-divider.vertical {
    height: 2em;
    width: .9px;
    margin: 0 8px;
    background: $color-divider;
}

.mdc-dialog__container>div {
    overflow: visible;
}

// Mat Tooltip
.cdk-overlay-container {
    z-index: 100000;
}

mat-tooltip-component {
    &>.mat-mdc-tooltip {
        &.pattern-tooltip {
            white-space: inherit;
            border: 1px solid $color-table-border;
            background-color: $color-white;
            color: $color-black-raising;
            font-size: 14px;
        }
    }
}

// Mat Spinner
.mat-mdc-progress-spinner {
    margin: 0 auto;

    circle {
        stroke: $color-navy;
    }
}

button.mat-mdc-dialog-actions button[disabled],
button.mat-mdc-button[disabled][disabled] {
    background: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.38) !important;
}


.mat-mdc-chip-set {
    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
        color: $color-white;
    }
}

.mdc-tooltip .mdc-tooltip__surface {
    background: $color-black-raising;
    height: auto;
    max-height: 100%;
}

/** Buttons */
.mdc-button {
    font-family: $font-family;
    font-weight: normal;
}

.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
    width: auto !important;
}

.cdk-overlay-pane {
    font-size: 12px;
}
