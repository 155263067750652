.fx-row {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}

.fx-row-reverse {
    flex-direction: row-reverse;
    box-sizing: border-box;
    display: flex;
}

.fx-column {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
}

.fx-column-reverse {
    flex-direction: column-reverse;
    box-sizing: border-box;
    display: flex;
}

.fx-wrap {
    flex-wrap: wrap;
}

.fx-nowrap {
    flex-wrap: nowrap;
}

.fx-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.fx-flex {
    display: flex;
}

.fx-inline {
    display: inline-flex;
}

.gap-5 {
    gap: 5px !important;
}

.gap-10 {
    gap: 10px !important;
}

.gap-15 {
    gap: 15px !important;
}

.gap-20 {
    gap: 20px !important;
}

.gap-25 {
    gap: 25px !important;
}

.gap-30 {
    gap: 30px !important;
}

.gap-40 {
    gap: 40px !important;
}

.fx-align-start {
    justify-content: flex-start;
}

.fx-align-stretch {
    justify-content: stretch;
}

.fx-align-center {
    justify-content: center;
    place-content: center
}

.fx-align-end {
    justify-content: flex-end;
}

.fx-align-space-around {
    justify-content: space-around;
}

.fx-align-space-between {
    justify-content: space-between;
}

.fx-align-space-evenly {
    justify-content: space-evenly;
}

.fx-align-center-start, .fx-align-end-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
}

.fx-align-center-start {
    place-content: center flex-start;
    justify-content: flex-start;
}

.fx-align-end-center {
    place-content: center flex-end;
    justify-content: flex-end;
}

.fx-align-stretch-start {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    place-content: stretch flex-start;
    box-sizing: border-box;
    flex: 1 1 0%;
    align-self: center;
    flex-direction: row;
}

.fx-align-cross {
    align-items: stretch;
    align-content: stretch;
}

.fx-align-cross-start {
    align-items: flex-start;
    align-content: flex-start;
}

.fx-align-cross-center {
    align-items: center;
    align-content: center;
}

.fx-align-cross-end {
    align-items: flex-end;
    align-content: flex-end;
}

.fx-align-cross-space-around {
    align-items: space-around;
    align-content: space-around;
}

.fx-align-cross-space-between {
    align-items: space-between;
    align-content: space-between;
}

.fx-align-cross-row-stretch {
    max-height: 100%;
}

.fx-align-cross-column-stretch {
    max-width: 100%;
}

.fx-align-cross-baseline {
    align-items: baseline;
    align-content: stretch;
}

.fx-flex-grow {
    box-sizing: border-box;
    flex: 1 1 100%;
}

.fx-flex-initial {
    box-sizing: border-box;
    flex: 0 1 auto;
}

.fx-flex-none {
    box-sizing: border-box;
    flex: 0 0 auto;
}

.fx-flex-nogrow {
    box-sizing: border-box;
    flex: 0 1 auto;
}

.fx-flex-noshrink {
    box-sizing: border-box;
    flex: 1 0 auto;
}

.fx-flex{
    flex: 1 1 0%;
}

.fx-flex-fill {
    width:	100%;
    height:	100%;
    min-width:	100%;
    min-height:	100%;
}

.fx-flex-5 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 5%;
}

.fx-flex-8 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 8%;
}

.fx-flex-10 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 20%;
}

.fx-flex-15 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 15%;
}

.fx-flex-20 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 20%;
}

.fx-flex-25 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 25%;
}

.fx-flex-30 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 30%;
}

.fx-flex-33-33 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 33.33%
}

.fx-flex-40 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 40%;
}

.fx-flex-50 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 50%;
}

.fx-flex-55 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 55%;
}

.fx-flex-60 {
   flex: 1 1 100%;
   box-sizing: border-box;
   max-width: 60%;
}

.fx-flex-75 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 75%;
}

.fx-flex-80 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 80%;
}

.fx-flex-85 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 85%;
}

.fx-flex-90 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 90%;
}

.fx-flex-95 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 95%;
}

.fx-flex-100 {
    flex: 1 1 100%;
    box-sizing: border-box;
    max-width: 100%;
}
