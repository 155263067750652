.ie {
    .widget {
        .common_Widget {
            .body {
                .lhs {
                    display: none !important;
                }
                .rhs {
                    display: none !important;
                }
            }
        }
        .layout_Border2 {
            &>.body {
                &>.lhs {
                    display: none !important;
                }
                &>.rhs {
                    display: none !important;
                }
            }
        }
    }
}
