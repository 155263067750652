@font-face {
    font-family: 'CustomFontAwesome';
    src:  url('../fonts/CustomFontAwesome.eot?ou06hu');
    src:  url('../fonts/CustomFontAwesome.eot?ou06hu#iefix') format('embedded-opentype'),
      url('../fonts/CustomFontAwesome.ttf?ou06hu') format('truetype'),
      url('../fonts/CustomFontAwesome.woff?ou06hu') format('woff'),
      url('../fonts/CustomFontAwesome.svg?ou06hu#CustomFontAwesome') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="fa-"],
[class*=" fa-"] {
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-Last-3-Months:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e901";
}

.fa-Last-6-Months:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e902";
}

.fa-Last-Month:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e903";
}

.fa-interstate-bottom:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e904";
    color: #595f72 !important;
}

.fa-interstate-top:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e905";
    color: #ccc !important;
}

.fa-interstate-small-bottom:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e906";
    color: #555 !important;
}

.fa-interstate-small-top:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e907";
    color: #555 !important;
}

.fa-interstate-small-arrow:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e908";
    color: #fff !important;
}

.fa-intrastate-bottom:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e909";
    color: #575d90 !important;
}

.fa-intrastate-top:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e90a";
    color: #ccc !important;
}

.fa-intrastate-small-bottom:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e90b";
    color: #555 !important;
}

.fa-intrastate-small-top:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e90c";
    color: #555 !important;
}

.fa-intrastate-small-arrow:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e90d";
    color: #fff !important;
}

.fa-users-class:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e90e";
}

.fa-death-icon:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e90f";
}

.fa-key-solid-1:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e910";
}

.fa-person-person:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e913";
}

.fa-ssn:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e914";
}

.fa-piggy-bank:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e915";
}

.fa-contributed-data:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e916";
}

.fa-under-18:before {
    font-family: 'CustomFontAwesome' !important;
    content: "\e900";
}
