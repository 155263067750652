@import 'assets/scss/theme-variables';

table {
    color: $color-table-font;
    border-collapse: collapse;
    font-size: 12px;
    min-width: 100%;
    height: 100%;
    border: 1px solid $color-grey-table-header-border;
    border-bottom-width: 1px !important;

    td, th {
        border: 1px solid $color-grey-table-header-border;
    }

    th {
        padding: 10px;
    }

    td {
        padding: 4px 7px;
        word-wrap: break-word;
    }

    thead {
        tr th {
            background-color: $color-table-header;
            border-top: 0;
        }
    }

    tbody {
        tr {
            &:nth-child(even) {
                background-color: $color-table-row-alt;
            }
        }
    }

    .center {
        text-align: center;
    }
}

.elements-table, .identities-table, .table-container, .fx-table-container {
    padding-top: 15px;

    .mw-75 {
        min-width: 75px !important;
    }

    .mw-85 {
        min-width: 85px !important;
    }

    .mw-128 {
        min-width: 128px !important;
    }

    .mw-50 {
        min-width: 50px !important;
    }

    td {
        word-break: break-word;
    }

    i {
        font-size: 18px;
    }

    .ellipsis-container {
        overflow: hidden;

        .text-ellipsis {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .results-scroll-container {
        height: 735px;
        overflow-x: hidden;
    }

    .break-all {
        word-break: break-all;
        min-width: 68px !important;
    }
}

.identities-table {
    .icon {
        text-align: center;
        font-size: 16px;
        color: $color-grey-davy;
    }
}

.elements-table {
    .body-cell {
        &:first-child {
            text-align: center;
            font-size: 16px;
            color: $color-grey-davy;
        }
    }
}

.cdk-table-container {
    .col-sm {
        width: 45px;
        min-width: 45px;
        max-width: 45px;
        text-align: center !important;
    }

    .col-select {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        justify-content: center;
        text-align: center !important;
    }

    .col-mpiid {
        width: 142px;
        min-width: 142px;
        max-width: 142px;
    }

    .col-name {
        width: 80px;
        min-width: 80px;
        max-width: 180px;
    }

    .col-state {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
    }

    .col-source {
        width: 80px;
        min-width: 80px;
        max-width: 380px;
    }

    .col-ssn {
        width: 85px;
        min-width: 85px;
        max-width: 85px;
        text-align: center !important;
    }

    .col-address {
        width: 140px;
        min-width: 140px;
    }

    .col-md {
        width: 140px;
        min-width: 140px;
        max-width: 140px;
    }

    .col-date-time {
        width: 80px;
        min-width: 80px;
        max-width: 80px;
    }

    .col-status {
        width: 100px;
        min-width: 100px;
        max-width: 130px;
    }
}

.fx-table-container {
    max-height: 690px !important;
    margin-bottom: 20px !important;

    &, cdk-virtual-scroll-viewport, tr {
        display: flex;
        flex-direction: row;
        width: 100%;
        box-sizing: border-box;
    }

    th {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center !important;
    }

    .fx-col {
        flex: 1;
    }

    .fx-col-sm {
        flex: 1 1 45px;
        min-width: 45px;
        max-width: 45px;
        text-align: center !important;
    }

    .fx-col-md {
        flex: 1 1 88px;
        min-width: 88px;
        max-width: 88px;
    }

    .fx-col-hhid {
        flex: 0 0 120px;
        min-width: 120px;
    }

    .fx-col-state {
        flex: 1 1 50px;
        min-width: 50px;
        max-width: 50px;
    }

    .fx-col-one-action {
        flex: 1 1 60px;
        min-width: 60px;
        max-width: 60px;
        text-align: center !important;
    }

    .fx-col-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex: 1 1 70px;
        min-width: 70px;
        max-width: 70px;
        text-align: center !important;
    }

    .fx-col-short-time {
        flex: 1 1 70px;
        min-width: 70px;
        max-width: 70px;
    }

    .content-center {
        display: flex;
        justify-content: center;
        place-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
    }

    tbody {
        display: flex;
        flex-direction: column;
        width: 100%;

        tr {
            overflow: hidden;
        }
    }

    th, td {
        text-align: left;
        align-content: center;
        box-sizing: border-box;
    }
}

.scroll-container {
    border: 1px solid $color-grey-table-header-border;
    max-height: 630px !important;
    overflow-x: hidden;

    table {
        height: 100%;
    }
}

::ng-deep {
    .center {
        .mat-sort-header-container {
            justify-content: center;
        }
    }
}
