$bg-primary: #335A8E;
$bg-secondary: #54739E;
//font
$font-base-size: 14px;
$font-family: "Source Sans Pro",
sans-serif;
$icon-font: "Font Awesome 5 Pro",
sans-serif;
$icon-custom: 'CustomFontAwesome';

//Tints Colors
//Teals
$color-teal-100: #00778B;
$color-teal-80: #0095AB;
$color-teal-60: #52A9B9;
$color-teal-40: #8ABFCC;
//Orange
$color-orange-100: #FF8200;
$color-orange-80: #F89A4A;
$color-orange-60: #FBB273;
$color-orange-40: #FDCA9C;
//Light Grey
$color-light-grey-100: #C8C9C7;
$color-light-grey-80: #D0D2D1;
$color-light-grey-60: #DADBDA;
$color-light-grey-40: #E4E5EA;
$color-light-grey-20: rgba(248, 248, 248, 1);
//yellow
$color-yellow-100: #E1CD00;
$color-yellow-80: #F3DE4C;
$color-yellow-60: #F6E57D;
$color-yellow-40: #F8ECA6;
//navy
$color-navy-100: #002F6C;
$color-navy-80: #335A8E;
$color-navy-60: #1b76c0;
//purple
$color-purple-100: #671E75;
$color-purple-80: #754C90;
$color-purple-60: #8D6EA5;
$color-purple-40: #575D90;
//green
$color-green-100: #00AF66;
$color-green-80: #3ABA96;
$color-green-60: #77C9AC;
$color-green-light: #4DD965;
$color-green-wcag: #1D872E;
//light blue
$color-light-blue-500: #2271A5;
$color-light-blue-100: #9BCBEB;
$color-light-blue-80: #ABD9F4;
$color-light-blue-60: #BFE1F7;
$color-light-blue-30: #71B0EB;
// Primary Colors
$color-red: #E7131A;
$color-navy: $color-navy-80;
$color-white: #ffffff;
$color-grey: #EDECEC;
//Secondary blacks
$color-black: #000000;
$color-black-raising: #252525;
//Secondary grays
$color-grey-davy: #555555;
$color-grey-taupe: #898989;
$color-grey-spanish: #959595;
$color-grey-pastel: #CCCCCC;
$color-grey-ghost-pastel: #F8F8F8;
$color-grey-cell-lines: #ddd;
$color-grey-disabled: #E4E4E4;
$color-grey-wcag-border: #898989;
$color-grey-wcag-font: #6B6B6B;
$color-grey-wcag-font-2: #6D6E71;
$color-grey-on-blue-bg: #3B3B3B;
$color-grey-on-orange-bg: #3B3B3B;
$color-grey-on-ghost-pastel-bg: #363636;
$color-grey-50-percent-opacity: rgba(0,0,0,.50);
//Speciality colors
$color-clusters: $color-black-raising;
$color-entities: $color-light-blue-100;
$color-elements: $color-orange-80;
//Warning color
$color-warning: #FFCC4D;
//Report colors
$color-report-red: #E7131A;
$color-report-yellow: #FFCC33;
$color-report-green: #009933;
//Table colors
$color-table-header: #EEEEEE;
$color-table-row-alt: #F9F9F9;
$color-table-border: #E4E4E4;
//Error colors
$color-input-error: #a94442;
$color-input-error-shadow: rgba(0, 0, 0, .075);
$color-error-background: #f2dede;
$color-error-border: #EBCCD1;
//Pagination colors
$color-pagination-footer: #EEEEEE;
$color-paginator-accessible-gray: #6c6c6c;
//Overlay colors
$color-input-disabled-background: rgba(204, 204, 204, 0.3);
$color-suggestive-cue-background: rgba(77, 217, 101, 0.0980392156862745);
// Input colors
$checked-slider-color: $color-green-wcag;
$unchecked-slider-color: rgba(204, 204, 204, 1);
$unchecked-slider-warn-color: red;

$color-divider: $color-grey-pastel;

//Risk colors
$color-high-risk: #E41111;
$color-medium-risk: #FFCC00;
$color-medium-risk-alt: #F5C400;
$color-medium-risk-alt-2: #F0CF2B;
$color-medium-risk-text: #000000;
$color-medium-risk-text-alt: #8A6E00;
$color-low-risk: #00802b;
$color-low-risk-alt: #21914E;
$color-informational: $color-grey-wcag-font;

// timeline colors:
$color-known-risk: #E7131A;
$color-identity-activity: $color-grey-wcag-font;
$color-safe-list: $color-green-wcag;
// links
$color-link-hover: #66AFE9;
$color-link-button: #1B73BB;
$color-link-button-dividers: #555555;
// Submenu
$color-submenu-text-line: #7D7D7D;
$color-bookmark: #707070;
// Green actions panel
$color-green-button: #50d969;
// Color yellow widget header
$color-yellow-widget-header: #fff37d;
// Status icon colors
$color-status-icon-green: $color-green-wcag;
$color-status-icon-orange: #FF9900;
$color-status-icon-red: #FF0000;
$color-status-icon-yellow: #FFF37D;

// comprehensive report
$share-address: #147cbb;
$color-shared-address: #147cbb;
$color-deceased: #E3151A;

// Popup/modal icon color variables
$popup-icon-color-warning: $color-warning;
$popup-icon-color-danger: #721c24;
$popup-icon-color-info: #0c5460;
$popup-icon-color-success: #155724;
$popup-icon-color-primary: #004085;

// Cluster detail page colors
$link-chart-brown-highlight: #856C60;
$link-chart-selected-default-icon-color: $color-navy-80;
$linkchart-selected-identity-icon-text-color: $color-white;
$link-chart-selected-centroid-icon-color: $link-chart-brown-highlight;
$link-chart-node-stroke: #66AFE9;
$cluster-details-default-row-selection-color: $color-navy-80;

// DPF styles
$intrastateColor: #575d90;
$interstateColor: #595f72;
$lexIdScoreColor: #ED1C24;

$angular-slider-color: $color-link-button;

// identity risk banners
$color-banner-header-text: #96AECF;
$color-banner-header-bg: #2E5383;
$color-banner-header-bg-text: #8CA6CA;
// identity risk
$color-identity-risk-leads: #419BD8;
$color-green-identity-risk: #4ea324;
$color-identity-risk-dashboard: #E07400;
$color-search-templates-icon: rgb(0, 119, 139);
// identity risk logo
$color-logo-dark-text: #0000103f;
$color-background-text-dark: #26446f;
$color-text-dark: #ffffff26;
$color-highlight-risk: #ebeff4;
$color-table-font: #333333;
// identity risk search results
$color-boder-gray: #C1C1C1;
$color-tab-ink-bar: #3281d5;
$color-grey-table-header-border: #E4E4E4;
// identity risk breadcrumbs
$color-breadcrumbs-border: #3281d5;
// identity risk chips
$color-chips-bg-hover: #3281d5;
// Announcements
$color-modal-accessible-white: #fefefe;
$color-modal-accessible-blue: #125996;
// PPA Usage Detail Report
$color-no-report-accessible-gray: #767676;

$color-banner-text: #03427b;
