@import 'assets/scss/theme-variables';

//primary colors
.color-red {
    color: $color-red;
}
.color-navy {
    color: $color-navy;
}
.color-white {
    color: $color-white;
}
.color-grey {
    color: $color-grey;
}

//Secondary blacks
.color-black {
    color: $color-black;
}
.color-black-raising {
    color: $color-black-raising;
}

//Secondary grays
.color-grey-davy {
    color: $color-grey-davy;
}
.color-grey-taupe {
    color: $color-grey-taupe;
}
.color-grey-spanish {
    color: $color-grey-spanish;
}
.color-grey-pastel {
    color: $color-grey-pastel;
}
.color-grey-ghost-pastel {
    color: $color-grey-ghost-pastel;
}

//Speciality colors
.color-clusters {
    color: $color-clusters;
}
.color-entities {
    color: $color-entities;
}
.color-elements {
    color: $color-elements;
}

//Report colors
.color-report-red {
    color: $color-report-red;
}
.color-report-yellow {
    color: $color-report-yellow;
}
.color-report-green {
    color: $color-report-green;
}

//Table colors
.color-table-header {
    color: $color-table-header;
}
.color-table-row-alt {
    color: $color-table-row-alt;
}
.color-table-border {
    color: $color-table-border;
}

.color-green-light {
    color: $color-green-light;
}

.color-green-wcag {
    color: $color-green-wcag;
}
