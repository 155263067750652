@import 'assets/scss/theme-variables';

.main-container {
    padding: 0 12px;
}

.footer-button-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin: 12px 0;
    padding: 10px;
    background: $color-grey-ghost-pastel;

    &:first-child {
        margin: 0 !important;
    }

    button {
        margin-left: 10px;
        text-transform: uppercase;
    }
}

.text-gray {
    color: $color-grey-wcag-font;
}

.text-info-wrap {
    padding: 15px 5px;
    margin: 0;
    font-size: 16px;
    text-align: left;

    p {
        margin: 0 !important;
    }
}
