// Mat accordion override
mat-accordion#sidenav-accordion {
    .mat-content {
        justify-content: center;
    }

    .mat-expansion-panel-spacing {
        margin: 0;
    }
}

// mat-sort-header-button
.mat-sort-header-button {
    outline: none !important;
}

// Bootstrap accordion panel heading override
.panel-heading {
    margin: 0;
    padding: 0;
}

// Bootstrap buttons
.btn {
    font-family: $font-family;
    font-size: 15px;
    min-width: 95px;
    border-radius: 5px;

    &.btn-primary {
        color: $color-white;
        background-color: $color-navy;
        border: solid 2px $color-navy;

        &:hover {
            border-color: $color-light-blue-30;
            background-color: $color-light-blue-30;
            box-shadow: none;
        }

        &:not(:disabled):not(.disabled):active {
            box-shadow: 0 0 0 0.2rem rgba(51, 90, 142, 0.5) !important;
        }
    }

    &.btn-light {
        color: $color-black;
        background-color: $color-white;
        border: solid 2px $color-navy;

        &:hover {
            border-color: $color-light-blue-30;
            color: $color-light-blue-30;
            box-shadow: none;
        }
    }

    &.btn-default {
        border: solid 2px $color-navy;
        color: $color-navy;
        background: $color-white;

        &:not(:disabled):not(.disabled):hover {
            background-color: $color-white;
            border: solid 2px $color-light-blue-100;
            color: $color-light-blue-100;
        }
    }

    &.btn-success {
        border: solid 2px $color-green-wcag;
        background-color: $color-green-wcag;
        box-shadow: none;

        &:hover {
            border: solid 2px $color-light-blue-30;
            background-color: $color-light-blue-30;
            box-shadow: none;
        }
    }
}

// Detail page score-breakdown
.chart_Column .dataRow .series-_average-_risk {
    fill: $color-grey-pastel !important;
}

input {
    &.form-control {
        font-size: 16px;
    }
}

// Dashboard Widgets
.dgrid_Table {
    .ui-widget {
        font-family: $font-family !important;
        font-size: 14px;
    }

    .dgrid {
        border: 5px solid #fff;

        .dgrid-header {
            border: none;
        }

        .dgrid-content {
            border: none;

            &.ui-widget-content {
                .ui-state-active {
                    color: $color-white;
                    background: $color-navy-80 !important;

                    .dgrid-cell {
                        a {
                            &.blue-link {
                                color: $color-white !important;
                            }
                        }

                        i {
                            &.col-icon {
                                color: $color-white;
                            }
                        }
                    }
                }
            }

            .dgrid-row {
                border: none;

                &:focus {
                    outline-color: $color-navy;
                }

                &.dgrid-row-even {
                    background: $color-table-row-alt;

                }

                &.dgrid-row-odd {
                    background: $color-white;
                }

                .dgrid-row-table {
                    .dgrid-cell {
                        padding: 10px;
                    }
                }

                .dgrid-cell {
                    i {
                        &.col-icon {
                            display: block;
                            text-align: center;
                            color: $color-navy;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }

    .dgrid-no-data {
        display: table;
        width: 100%;
        height: 100%;

        .dojoxGridNoData {
            display: table-cell;
            color: $color-grey-wcag-font;
            text-align: center;
            vertical-align: middle;
            font-size: 18px;

            i {
                font-size: 60px;
                display: block;
                margin-bottom: 20px;
                color: $color-grey-davy;
            }
        }
    }
}

// Checkbox Slider
.slider-checkbox {
    position: relative;

    input {
        margin: 0px;
        margin-top: 1px;
        cursor: pointer;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        -moz-opacity: 0;
        -khtml-opacity: 0;
        position: absolute;
        z-index: 1;
        top: 0px;
        left: 0px;
        background: red;
        width: 40px;
        height: 20px;

        &:checked+.slider-label {
            &:before {
                background-color: $checked-slider-color;
                content: "";
                padding-left: 6px;
            }

            &:after {
                left: 21px;
            }
        }
    }

    .slider-label {
        position: relative;
        padding-left: 46px;

        &:before,
        &:after {
            position: absolute;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            transition: background-color 0.3s, left 0.3s;
        }

        &:before {
            content: "";
            color: #fff;
            box-sizing: border-box;
            font-family: $icon-font;
            padding-left: 23px;
            font-size: 12px;
            line-height: 20px;
            background-color: $unchecked-slider-color;
            left: 0px;
            top: 0px;
            height: 20px;
            width: 40px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
        }

        &:after {
            content: "";
            letter-spacing: 20px;
            background: #fff;
            left: 1px;
            top: 1px;
            height: 18px;
            width: 18px;
        }
    }

    .slider-warn {
        &:before {
            background-color: $unchecked-slider-warn-color;
        }
    }
}

.scorecard-grid {
    .dgrid-row-table {
        a {
            color: $color-navy-80;
            text-decoration: underline;

            &:hover {
                color: $color-link-hover;
            }
        }
    }
}

.deceased-box {
    background-color: $color-red;
    border-radius: 5px;
    font-weight: bold;
    font-size: 28px;
    color: white;
    display: block;
    width: 23px;
    height: 23px;
    text-align: center;
    padding-top: 6px;

    i {
        text-align: center;
        vertical-align: baseline;
    }

    .fa-death-icon {
        color: $color-white !important;
        font-size: 11px !important;
    }
}
