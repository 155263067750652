/* You can add global styles to this file, and also import other style files */
// Bootstrap variable overrides
@import 'assets/scss/bootstrap/variables';

@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import 'assets/scss/flex-layout';
@import "assets/scss/forms/custom_datepicker.scss";
@import "assets/scss/forms/custom_controls.scss";
@import "assets/scss/_overrides.scss";
@import "assets/scss/material-override.scss";
@import "assets/scss/_otto_overrides.scss";
@import "assets/scss/theme-colors.scss";
@import "assets/scss/_ie.scss";
@import 'assets/scss/tables';
@import 'assets/scss/custom-layout';

@font-face {
    font-family: 'Source Sans Pro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url('assets/fonts/source-sans-pro/source-sans-pro-v11-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url('assets/fonts/source-sans-pro/source-sans-pro-v11-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('assets/fonts/source-sans-pro/source-sans-pro-v11-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('assets/fonts/source-sans-pro/source-sans-pro-v11-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('assets/fonts/source-sans-pro/source-sans-pro-v11-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('assets/fonts/source-sans-pro/source-sans-pro-v11-latin-regular.svg#SourceSansPro') format('svg');
    /* Legacy iOS */
}

body {
    font-size: 14px;
    font-family: 'Source Sans Pro', 'Open Sans', Arial, Helvetica, 'Sans-Serif';
    background-color: $color-grey;

    &:not(.menu-on-top) {
        &.desktop-detected {
            min-height: inherit !important;
            padding: 0;
            margin: 0;
            overflow: visible;
        }
    }

    a {
        color: $color-link-button;

        &:hover {
            color: $color-link-button;
            text-decoration: underline;
        }
    }

    .mat-dialog-actions button {
        text-transform: uppercase;
    }

    .pull-right {
        float: right;
    }

    .pull-left {
        float: left;
    }

    .icon-container {
        position: relative;
        margin-bottom: 5px;

        i, span {
            &.top-right {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
            }
        }

        span {
            width: 15px;
            height: 15px;
            text-align: center;
            font-size: 10px;
            border-radius: 50%;
            overflow: hidden;

            &.none {
                color: $color-white;
                background-color: $color-grey-wcag-font;
            }
            &.high {
                color: $color-white;
                background-color: $color-high-risk;
            }
        }
    }

    .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        place-content: center;
        width: 58px;
        height: 58px;
        border-radius: 29px;
        background-color: $color-white;
        border: 2px solid $color-grey-pastel;
        text-align: center;
    }

    .risk {
        text-transform: capitalize;
        &.risk-container {
            border-radius: 7px;
            height: 25px;
            width: 25px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $color-white !important;

            &.large {
                height: 50px;
                width: 50px;
            }
        }

        &.none {
            &.background {
                background-color: $color-grey-wcag-font !important;
                color: $color-white;
            }

            &.border {
                border: 1px solid $color-informational !important;
            }

            &.color {
                color: $color-informational;
            }

            &.fill {
                fill: $color-informational !important;
            }
        }

        &.low {
            &.background {
                background-color: $color-low-risk !important;
                color: $color-white;
            }

            &.border {
                border: 1px solid $color-low-risk !important;
            }

            &.color {
                color: $color-low-risk;
            }

            &.fill {
                fill: $color-low-risk !important;
            }
        }

        &.medium {
            &.background {
                background-color: $color-medium-risk !important;
                color: $color-medium-risk-text;
            }

            &.border {
                border: 1px solid $color-medium-risk !important;
            }

            &.color {
                color: $color-medium-risk;
            }

            &.fill {
                fill: $color-medium-risk !important;
            }
        }

        &.high {
            &.background {
                background-color: $color-high-risk !important;
                color: $color-white;
            }

            &.border {
                border: 1px solid $color-high-risk !important;
            }

            &.color {
                color: $color-high-risk;
            }

            &.fill {
                fill: $color-high-risk !important;
            }
        }
    }
}

.mdi-action {
    font-size: 24px;
}

.blue-link {
    color: $color-link-button !important;
    cursor: pointer !important;
    text-decoration: none !important;

    &:hover {
        text-decoration: underline !important;
    }
}

a.disabled{
    pointer-events: none !important;
    opacity: .5 !important;
    cursor: default !important;
}

body.isFullscreen {
    overflow: hidden !important;

    .header-container {
        z-index: 0;
    }
}

.required {
    color: $color-input-error !important;
}

.snackbar-container {
    margin-top: 220px !important;
    padding: 10px !important;
    color: $color-grey !important;

    button {
        color: $color-white !important;
        font-weight: bold;
    }

    &.error {
        background-color: $color-high-risk !important;
    }

    &.success {
        background-color: $color-low-risk !important;
    }
}

.empty-list-container {
    padding: 10px 12px;
    margin-bottom: 10px;
    border: 1px solid $color-table-border;

    p {
        font-size: 18px;
        margin: 0 !important;
    }
}

.scroll-x {
    overflow-x: auto !important;
}

.disp-block {
    display: block !important;
}

.disp-inline-block {
    display: inline-block !important;
}

@media print {
    *,
    *:before,
    *:after {
        -webkit-print-color-adjust: exact;
    }

    .no-print,
    .header-container,
    .footer-container {
        display: none !important;
    }

    @page {
        margin: 0mm;
    }
}
